import { useTranslation } from 'react-i18next';

import { ResponsiveProps } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export function NotFound({isMobile}: ResponsiveProps) {
  const [t] = useTranslation('home');
  
  return <div className="mt-5">
    <div className='container-fluid d-flex mt-5 mb-5 px-2 py-1 mb-sm-0 px-sm-5 py-sm-4'>
      <div className='col-sm-1'></div>
      <div style={{height: '60vh'}} className='col-12 col-sm-10 pt-0 pt-sm-5 d-flex align-items-center justify-content-center mb-sm-5'>
        <div style={{
          width: isMobile ? '100vw' : '60vw',
          backgroundColor: isMobile ? 'white' : 'var(--milk)'
          }}
          className={(isMobile ? 'flex-column' : 'flex-row') + ' d-flex juaftify-content-between align-items-center col-6 p-5'}
        >
          <div className='me-5' style={{color: 'var(--deep-sky-blue)'}}>
            <FontAwesomeIcon icon='arrow-left' style={{width: '25vh', height: '25vh'}} />
          </div>
          <div style={{height: '25vh'}} className='d-flex flex-column justify-content-center'>
            <div className='link mt-3 h5'>
              <span className='no-link h3'>{t('page')}</span>
              <br/>
              <Link className='link h5' to='/'>
                <span>{t('home')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-1'></div>
    </div>
  </div>
}